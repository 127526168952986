import {combineReducers} from 'redux';
import statistics from './statistics';
import company from './company';
import runtime from './runtime';
import filter from './filter';
import user from './user';

export default combineReducers({
  statistics,
  runtime,
  company,
  filter,
  user,
});
