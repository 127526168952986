import {SET_COMPANY_STATE} from 'constants/redux';

const initialState = {
  error: {},
  data:  {},
  gifts: {
    collection: [],
  },
};

export default function stat(state = initialState, action) {
  switch (action.type) {
    case SET_COMPANY_STATE:

      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
