import {SET_FILTER_STATE} from 'constants/redux';
import {COMPANY_FILTER} from 'constants/api';

const initialState = {
  filter: COMPANY_FILTER,
};

export default function stat(state = initialState, action) {
  switch (action.type) {
    case SET_FILTER_STATE:

      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
