import {SET_STATISTICS_STATE} from 'constants/redux';

const initialState = {
  error:      {},
  collection: [],
};

export default function stat(state = initialState, action) {
  switch (action.type) {
    case SET_STATISTICS_STATE:

      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
