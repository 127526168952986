const env = __ENV__ || 'development';

export const DNS_PROD = 'https://3a716c37d9d642e9a2a01db55e211d1e@sentry.swiftgift.me/33';

export const DNS_BY_ENV = {
  development: '',
  production:  DNS_PROD,
  staging:     '',
};

export const DNS = DNS_BY_ENV[env];
