/* eslint-disable no-underscore-dangle */

// Main
export const env = __ENV__ || 'development';

export const API_TEST_URL = 'api-staging.swiftgift.me/api/v1/';
export const GIFT_SITE_URL = `https://gift${env === 'production' ? '' : '-staging'}.swiftgift.me`;

// Api Routes
export const REG_URL          = 'users';
export const LOGIN_URL        = 'users/login';
export const PROFILE_URL      = 'users/profile';
export const RESET_PASS       = 'users/reset-password';
export const PAYMENTS_URL     = 'payments';
export const COMPANY_URL      = 'companies';
export const COMPANY_FIELDS   = [
  'id',
  'url',
  'stat',
  'name',
  'email',
  'status',
  'card_created',
  'billing_type',
  'integration_type',
].join(',');
export const COMPANY_FILTER = '&_status=active';

export const STAT_URL         = `${COMPANY_URL}?fields=${COMPANY_FIELDS}`;
const GIFTS_URL       = 'gifts';
const GIFTS_FIELDS = [
  'fee',
  'code',
  'sender',
  'viewed',
  'status',
  'created',
  'currency',
  'basket_amount',
  'idempotency_key',
  'delivery_address',
  'external_order_id',
].join(',');

export const STAT_GIFTS = `${GIFTS_URL}?fields=${GIFTS_FIELDS}`;
